<template>
    <div>
        <header-admin-pages
            title="Часто задаваемые вопросы"
            :counter="getAllQuestions.length"
            :button="headerButton"/>
      <form class="filters" @submit.prevent="emailMarket">
        <div>
          <tags @dataTags="dataTags" :startValue="getEmailMarket" placeholder="введите почтовые адреса через запятую" />
        </div>
        <div class="wrapper-btn-filters">
          <input class="btn-blue" type="submit" value="Сохранить">
        </div>
      </form>

        <div class="accordion-block__body">
            <table>
                <tr>
                    <td>Вопрос</td>
                    <td>Ответ</td>
                    <td class="drop-icon"></td>
                </tr>
                <tr v-for="(item, i) of getAllQuestions" :key="i+'question'+item.id">
                    <td>{{ item.question }}</td>
                    <td v-html="item.answer"></td>
                    <td class="drop-icon">
                    <drop-list @clickItem="clickItem"
                                :id-item="item.id"
                                :edit-link="`asked_questions/${item.id}/edit`"/>
                    </td>
                </tr>
            </table>

        </div>

    </div>
</template>
<script>
import HeaderAdminPages from '@/components/Admin/HeaderAdminPages'
import DropList from '@/components/Admin/DropList'
import Tags from '@/components/Admin/Tags'
export default {
  name: 'AskedQuestions',

  data () {
    return {
      headerButton: {
        title: 'Добавить вопрос',
        link: '/admin/asked_questions/create'
      }
    }
  },
  created () {
    this.$store.dispatch('setEmailMarket', 'Часто задаваемые вопросы')
    this.$store.dispatch('setAllQuestions')
  },
  methods: {
    clickItem (value) {
      if (value.value === 'del') {
        const consent = confirm('Вы уверены, чтобы хотите удалить вопрос?')
        if (consent) {
          this.$store.dispatch('deleteQuestionAdmin', value.id)
        } else {
          this.$noty.warning('Удаление отменено')
        }
      }
      this.$forceUpdate()
    },
    emailMarket () {
      console.log('form')
      this.$store.dispatch('updateEmailMarket', { title: 'Часто задаваемые вопросы', emails: this.emails })
    }
  },

  computed: {
    getAllQuestions () {
      return this.$store.getters.getAllQuestions
    },
    getEmailMarket () {
      return this.$store.getters.getEmailMarket
    }
  },

  components: {
    HeaderAdminPages,
    DropList,
    Tags
  }
}
</script>
<style lang="scss" scoped>
.filters{
  margin-top: 1.37rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #D5D6D9;
  padding-bottom: 2rem;
}
.accordion-block {
    border: .06rem solid #E5E8EE;
    border-radius: 0.5rem;
    margin-bottom: 1.875rem;
    font-size: .875rem;
    line-height: 1.57;

    &.active {
      box-shadow: 0 .94rem 1.56rem rgba(189, 205, 221, 0.35);
    }

    header {
      color: #1F3245;
      font-weight: 600;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.25rem 1.875rem;

      & p { margin-right: 5rem; }
      & i {
        border: .1875rem solid #9D9D9D;
        border-width: 0 .1875rem .1875rem 0;
        border-radius: .0625rem;
        display: inline-block;
        padding: .1875rem;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        margin-bottom: .0625rem;
      }
      .active {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }
    }
  }
.content-sb{
    margin-bottom: 2rem;
}
.accordion-block__body{
    padding: 0 1.875rem;
}
  table{
    margin-top: 1rem;
    border-spacing: 0;
    border-collapse: collapse;

    tr{
      padding: 0;
      border-bottom: 1px solid #D5D6D9;

      td {
        max-width: 23%;
        font-size: .812rem;
        line-height: 154%;
        color: #1F3245;
        padding: 1.375rem 1.375rem 1.375rem 0;
        a {
          color: #246CB7;
        }
        img {
          cursor: pointer;
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
        }
      }
      &:first-of-type td{
        padding: 1rem 0;
        color: #5E686C;
      }
    }
  }
@media screen and (max-width: 768px){
}
@media screen and (max-width: 420px){
  .accordion-block{
    header{
      padding: 1.25rem 1.38rem;
      p{
        margin-right: .125rem;
      }
    }
  }
}
</style>
